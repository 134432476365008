@font-face {
    font-family: 'Berthold Akzidenz Grotesk BE';
    src: url('assets/AkzidenzGroteskBE-BoldExIt.eot?#iefix') format('embedded-opentype'),
        url('assets/AkzidenzGroteskBE-BoldExIt.woff2') format('woff2'),
        url('assets/AkzidenzGroteskBE-BoldExIt.woff') format('woff'),
        url('assets/AkzidenzGroteskBE-BoldExIt.ttf') format('truetype'),
        url('assets/AkzidenzGroteskBE-BoldExIt.svg#AkzidenzGroteskBE-BoldExIt') format('svg');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

canvas {
  outline: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0); /* mobile webkit */
}

.App {
  font-family: sans-serif;
  text-align: center;
}

#root {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

.credits {
  position: fixed;
  bottom: 25px;
  left: 25px;
  z-index: 10;
  color: white;
  font-size: 13px;
  font-family: sans-serif;
  font-weight: bold;
  letter-spacing: -0.02em;
  line-height: 1.3;
  opacity: 0.8;
  transition: all 0.6s;
}

.title {
  font-style: italic;
  font-family: 'Berthold Akzidenz Grotesk BE', sans-serif;
}

.subtitle {
  font-size: 12px;
}

.loading {
  width: 80vmin;
  text-align: center;
  z-index: 10;
  transition: all 0.6s;
  opacity: 0.9;
}


text {
  fill: #F9D63D;
}

.circle-text {
  font-size: 14.5px;
  letter-spacing: 11px;
  font-family: 'Berthold Akzidenz Grotesk BE';
  font-weight: bold;
  font-style: italic;
}

.progress {
  font-size: 9px;
  font-family: 'Berthold Akzidenz Grotesk BE';
  font-weight: bold;
  font-style: italic;
}
